import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { HelpCenterTypes, helpCenterTypesValues } from '../definitions/help-center.enum';
import { CustomerModuleKey } from '../models/customer';
import { HelpTicketStore } from '../stores/help-ticket.store';
import { CustomerService } from './customer.service';

export const helpCategoryGuard: CanActivateFn = (route) => {
  const helpTicketStore = inject(HelpTicketStore);
  const router = inject(Router);
  const customerService = inject(CustomerService);
  let helpCategory = route.params.option;

  if (!helpCenterTypesValues.includes(helpCategory)) {
    return router.parseUrl('/help-center');
  }

  if (
    !customerService.isCustomerModuleEnabled(CustomerModuleKey.PAYMENT_MANAGEMENT) &&
    helpCategory === HelpCenterTypes.InvoicesAndPayments
  ) {
    helpCategory = HelpCenterTypes.Tutorship;
  }

  if (helpCategory !== HelpCenterTypes.TechnicalSupport) {
    if (helpTicketStore.openTicketList === undefined) {
      helpTicketStore.requestMapping[helpCategory].subscribe((response) => {
        helpTicketStore.openTicketList = response;
      });
    }
  } else {
    helpTicketStore.openTicketList = null;
  }

  return true;
};
